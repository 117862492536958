.footer-container {
    background-image: url('../../Assets/bigCoins.svg');
    /* Add any other styling properties for the container */
}

.footer-content {
    background: rgba(0, 0, 0, 0.60);
    display: flex;
    backdrop-filter: blur(8px);
    justify-content: center;
    align-items: center;
    /* Add any other styling properties for the content */
}

.pages-list {
    display: table-column;
    /* Corrected value */
    margin-left: 25%;
    padding: 30px;
    height: 243.064px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10.021px;
    flex-shrink: 0;
    color: white;
    font-size: 1.3rem;
    font-family: sans-serif;
    line-height: 1.5;
}

.socialiconsContainer {
    /* background-color: red; */
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 15%;
}