/* Games.css */
.MainContainer {
    display: flex;
    flex-direction: column;
}

.games-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    padding: 20px;
    margin-top: 4%;
}

.icon {
    color:
        #1500B1;
    margin-right: 3px;
}

.text-container {
    display: flex;
    justify-content: space-around;
    text-align: center;
    /* margin-top: 20px; */
    color: white;
    width: 70%;
    gap: 37%;
}

.left-text,
.right-text {
    width: 45%;
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Inter', sans-serif;
}

.right-text {
    display: flex;
    align-items: center;
    background-color: black;
    border-radius: 15px;
    overflow: hidden;
    justify-content: baseline;
    background: linear-gradient(180deg, #000 100.00%, rgba(0, 0, 0, 0.00) 40.13%);
    /* border: 0.1px solid yellow; */
    margin-right: 8%;
}

.button-container {
    padding: 10px;
    border-radius: 5px;
    width: 40%;
    /* Adjust the width as needed */
}

button {
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 50%;
    /* Use 100% width within the container */
    height: 80%;
    border: 0px solid #1500B1;
    font-size: 1.4rem;
    font-style: bold;
}

.active-button {
    background-color: #F5D919;
    color: black;
    border: 5px solid #F5D919;
    margin-right: 10px;
    width: 50%;
    height: 80%;
    margin-left: 2%;
    margin-bottom: 2%;
    margin-top: 2.1%;
    font-style: bold;
    transition: all 0.3s ease; /* Add a transition effect for all properties over 0.3 seconds with ease-in-out timing function */

}

.inactive-button {
    background-color: transparent;
    color: white;
    margin-right: 10px;
}

/* Existing CSS */
.gameslist-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    /* background-color: red; */
    width: 80%;
}

.black-container {
    background-color: black;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    width: calc(20% - 20px);
    color: white;
    font-size: 1.6rem;
    margin: 55px 10px 5px 10px;
    margin-bottom: 2%;
    font-family: sans-serif;
    box-sizing: border-box;
}
.black-container h3 {
    font-size: 1.8rem;
}

.yellow-button {
    background-color: #F5D919;
    /* color: black; */
    color: #1500B1;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 4%;
    width: 100%;
    margin-top: 4%;
}

/* Added hover effect */
.yellow-button:hover,
.yellow-button:active,
.yellow-button:focus {
    background: #FDFF02;
    box-shadow: 0px 0px 19px 0px #FDFF01;
    color: black;
}

.casinoImage {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 10px;
}

/* Games.css */

/* Your existing styles */

@media (min-width: 800px) and (max-width: 1166px) {

    .text-container {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
        gap: 5%;
        /* Adjust the gap for smaller screens */
    }

    .left-text,
    .right-text {
        width: 35%;
        /* background-color: green; */
        /* Adjust the width for full width on smaller screens */

    }

    .right-text {
        margin-right: 0;
        /* background-color: red; */
        /* Remove margin-right on smaller screens */
    }


    .button-container {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
    }

    button {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
        margin-left: 0;
        /* Remove margin-left on smaller screens */
    }

    .active-button {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
        margin-left: 0;
        /* Remove margin-left on smaller screens */
    }

    .gameslist-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-self: center;
        /* background-color: red; */
        width: 100%;
    }

    /* Additional adjustments for smaller screens */
}

@media (max-width: 1335px) {
    .text-container {
        width: 90%;
        /* Adjust the width for full width on smaller screens */
        gap: 5%;
        /* Adjust the gap for smaller screens */
    }

    .left-text,
    .right-text {
        width: 40%;
        /* background-color: green; */
        /* Adjust the width for full width on smaller screens */

    }

    .right-text {
        margin-right: 0;
        /* background-color: red; */
        /* Remove margin-right on smaller screens */
    }


    .button-container {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
    }

    button {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
        margin-left: 0;
        /* Remove margin-left on smaller screens */
    }

    .active-button {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
        margin-left: 0;
        /* Remove margin-left on smaller screens */
    }

    /* Additional adjustments for smaller screens */
}

@media (min-width: 354px) and (max-width: 768px) {
    .text-container {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
        gap: 5%;
        /* Adjust the gap for smaller screens */
    }

    .left-text {
        width: 50%;
        font-size: 1.9rem;
        margin-top: 3%;
    }

    .right-text {
        width: 50%;
        font-size: 1.9rem;
        /* Adjust the width for full width on smaller screens */
    }

    .right-text {
        margin-right: 0;
        /* Remove margin-right on smaller screens */
    }


    .button-container {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
    }

    button {
        width: 100%;
        /* margin-left: 10; */
    }

    .active-button {
        width: 100%;
        /* Adjust the width for full width on smaller screens */
        margin-left: 0;
    }

    .gameslist-container {
        display: table-column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-self: center;
        /* background-color: red; */
        width: 100%;
    }

    .black-container {
        /* background-color: green; */
        /* display: table-column; */
        /* padding: 1%; */
        /* width: 50%; */
        width: 80%;
        margin-left: 10%;
    }

    .casinoImage {
        width: 100%;
    }

    .yellow-button {
        height: 6vh;
        width: 100%;
    }

    /* Additional adjustments for smaller screens */
}

/* Your existing styles */