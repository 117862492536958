@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Plus+Jakarta+Sans:wght@700&display=swap");

:root {
  --Heading-font-family: "Inter", sans-serif;
  --SubHeading-font-family: "Inter", sans-serif;

  /* --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%); */

  --color-bg: #000622;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #FDFF01;
  --color-subtext: #ff8a71;
}
