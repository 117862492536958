.home_video_player {
    width: 98%;
}

.slick-next {
    right: 25px !important;
    opacity: 0.3;
    color: #fff;
    cursor: pointer;
}

.slick-next:before {
    font-size: 204px;
    /* Adjust as needed */
}
/* Custom styles for previous and next arrows */
.custom-prev-arrow,
.custom-next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px; /* Increase arrow container width */
    height: 40px; /* Increase arrow container height */
    background-color: rgba(253, 255, 1, 0.7); /* Change arrow background color with opacity */
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styles for arrow icon */
.custom-prev-arrow img,
.custom-next-arrow img {
    width: 20px; /* Increase icon width */
    height: 20px; /* Increase icon height */
}

/* Styles for previous arrow */
.custom-prev-arrow {
    left: 10px;
    /* Adjust distance from left edge */
}

/* Styles for next arrow */
.custom-next-arrow {
    right: 10px;
    /* Adjust distance from right edge */
}

@media (min-width: 354px) and (max-width: 768px) {
    .home_video_player {
        width: 10%;
    }
}