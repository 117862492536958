.NavBar_Main_Container {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  padding: 2.0rem 2.1% 1.4rem 3.5%;
  position: fixed;
  z-index: 995;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 0.9));
  height: 16%;
}


/* .Active_Header {
  transition: 400ms ease-in-out;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0) 40.13%);
} */

.Logo_Text_style {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Logo_Saloon_Text_Style {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: var(--SubHeading-font-family);
}

.Join_Button_Style {
  padding: 12px;
  padding-left: 48px;
  padding-right: 48px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #353535;
  color: white;
  margin-right: 10px;
  font-family: var(--SubHeading-font-family);
  cursor: pointer;
}

.Join_Button_Style_Small_Device {
  padding: 10px;
  padding-left: 40px;
  padding-right: 40px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #353535;
  color: white;
  margin-right: 0px;
  margin-top: 5px;
  font-family: var(--SubHeading-font-family);
  cursor: pointer;
}

.Navlink_Main_Conatiner {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.Navlink_Wrapper {
  display: flex;
  width: 30vw;
  align-items: center;
  justify-content: space-between;
  --color-text: #FDFF01;
  gap: 19px;
}

.Navlink_Wrapper a {
  font-size: 18px;
  color: var(--color-text);
  text-decoration: none;
  border-radius: 7.075px;
  border: 0px solid #1500B1;
  background: transparent;
  transition: background 0.3s ease, color 0.3s ease;
}

.Navlink_Wrapper a:hover,
.Navlink_Wrapper a:active,
.Navlink_Wrapper a:focus {
  background: #FDFF02;
  box-shadow: 0px 0px 19px 0px #FDFF01;
  color: black;
}

.Button_Main_Container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.Button_Drop_Container {
  display: flex;
  align-items: center;
  background-color: #6fa66b;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}

.Button_Drop_Container_Small_Device {
  display: flex;
  align-items: center;
  background-color: #6fa66b;
  border-radius: 5px;
  padding-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
}

.Select_Option_Style {
  font-size: 1.5rem !important;
  color: black;
  font-family: var(--SubHeading-font-family);
}

.Select_Option_Button {
  padding: 1px;
  font-size: 1.5rem;
  /* width: 10rem; */
  font-family: var(--SubHeading-font-family);
}

.Select_Option_Button_Small_Device {
  font-size: 1.5rem;
  /* padding-right: 5px; */
  font-family: var(--SubHeading-font-family);
}

.Select_Option_Style_Small_Device {
  font-size: 1.5rem;
  margin-top: 5px;
}

.Link_Style {
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
  font-family: var(--Heading-font-family);
  font-weight: 600;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 25px;
  cursor: pointer;
}

.Link_Style:hover {
  background-color: rgba(0, 0, 0, 0.5);
  font-family: var(--Heading-font-family);
  font-weight: 600;
  color: white;
}

.newMenu:hover {
  background-color: rgba(0, 0, 0, 0.5);
  font-family: var(--Heading-font-family);
  width: 60%;
  font-weight: 600;
  color: white;
}

.Link_Style_Scroll {
  font-size: 1.5rem;
  text-decoration: none;
  color: black;
  font-family: var(--SubHeading-font-family);
  font-weight: 600;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 25px;
  cursor: pointer;
}

.Link_Style_Scroll:hover {
  background-color: rgba(0, 0, 0, 0.5);
  font-family: var(--SubHeading-font-family);
  font-weight: 600;
}

.Link_is_Active {
  background-color: yellow;
  color: black;
}

.Link_Style_Small_Device {
  font-size: 1.5rem;
  text-decoration: none;
  padding-top: 15px;
  font-family: var(--SubHeading-font-family);
  font-weight: 600;
  padding: 10px;
  border-radius: 25px;
  min-width: 65%;
  text-align: center;
}

.Navbar_Image_Style {
  height: 22px;
  width: 22px;
  border-radius: 100px;
}

.Select_Container {
  display: flex;
  align-items: center;
}

.NavLink_App_Smaller_Devices {
  display: none;
}

.NavLink_App_Smaller_Overlay {
  position: absolute;
  top: 60px;
  left: -50px;
  right: 0;
  width: 100px;
  /* margin-left: auto;
  margin-right: auto; */
  background-color: yellow;
  /* opacity: 0.9; */
  transition: 0s ease;
  flex-direction: column;
  overflow: hidden;
  z-index: 5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

.Navlink_App_Links_Wrapper_SmallScreen_links {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  padding-top: 5%;
  margin-bottom: 5%;
}

.Nav_Image_Style {
  height: auto;
  width: 70%;
  /* margin-bottom: 30%; */
}



@media (min-width: 1167px) and (max-width: 1440px) {
  .NavBar_Main_Container {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    padding: 2.0rem 2.1% 1.4rem 3.5%;
    position: fixed;
    z-index: 995;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 0.9));
    height: 20%;
  }

  .Navlink_Wrapper {
    display: flex;
    width: 40vw;
    align-items: center;
    justify-content: space-between;
    --color-text: #FDFF01;
    gap: 16px;
  }
}

@media (max-width: 1166px) {
  .NavBar_Main_Container {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
    padding: 2.0rem 2.1% 1.4rem 3.5%;
    position: fixed;
    /* z-index: 995; */
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 0.9));
    height: 20%;
  }

  .Navlink_Wrapper {
    display: flex;
    width: 50vw;
    align-items: center;
    justify-content: space-between;
    --color-text: #FDFF01;
    gap: 16px;
  }
}



@media (min-width: 554px) and (max-width: 768px) {
  .NavBar_Main_Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 5.5rem 2.1% 1.4rem 1.5%; */
    padding-right: 55%;
    padding-top: 15%;
    position: fixed;
    z-index: 995;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 0.9));
    height: 10%;
  }

  .Navlink_Wrapper {
    display: flex;
    width: 80vw;
    align-items: center;
    justify-content: space-between;
    --color-text: #FDFF01;
    gap: 16px;
  }

  .NavLink_App_Smaller_Devices {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    right: 10px;
    background-color: transparent;
    /* border: 1px solid #ccc; */
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .Navlink_Wrapper {
    display: none;
  }

  .Nav_Image_Style {
    height: auto;
    width: 50%;
    margin-bottom: 60%;
  }

  .Toggle_Btn_CLose {
    background-color: transparent;
  }

  .Toggle_Btn_Open {
    background-color: transparent;
  }
}

@media (min-width: 354px) and (max-width: 467px) {
  .NavBar_Main_Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 5.5rem 2.1% 1.4rem 1.5%; */
    padding-right: 55%;
    padding-top: 15%;
    position: fixed;
    z-index: 995;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 0.9));
    height: 15%;
  }

  .Navlink_Wrapper {
    display: flex;
    width: 80vw;
    align-items: center;
    justify-content: space-between;
    --color-text: #FDFF01;
    gap: 16px;
  }

  .NavLink_App_Smaller_Devices {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    right: 10px;
    background-color: transparent;
    /* border: 1px solid #ccc; */
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .Navlink_Wrapper {
    display: none;
  }

  .Nav_Image_Style {
    height: 60%;
    width: 50%;
    margin-bottom: 30%;
  }

  .Toggle_Btn_CLose {
    background-color: transparent;
  }

  .Toggle_Btn_Open {
    background-color: transparent;
  }
}

/* @media screen and (min-width: 787px) and (max-width: 950px) {
  .Navlink_Wrapper {
    display: flex;
    width: 50vw;
    align-items: center;
    justify-content: space-between;
  }
} */

/* @media screen and (min-width: 951px) and (max-width: 1050px) {
  .Navlink_Wrapper {
    display: flex;
    width: 35vw;
    align-items: center;
    justify-content: space-between;
  }
} */

/* @media screen and (min-width: 1051px) and (max-width: 1750px) {
  .Navlink_Wrapper {
    display: flex;
    width: 30vw;
    align-items: center;
    justify-content: space-between;
  }
} */

.logoContainer {
  display: flex;
  align-items: center;
  /* background-color: red; */


}

.newMenu {
  font-size: 1.5rem;
  color: black;
  font-weight: 500;
  margin-left: 4rem;
  font-family: var(--Heading-font-family);
  cursor: pointer;
}

/* @media screen and (max-width: 1290px) {

  .Navlink_Wrapper {
    width: 57vw;
  }
} */