* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

  body {
    overflow: auto;
  }

html {
  font-size: 62.5%;
}
