/* home.css or create a new CSS file */
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: linear-gradient(180deg, #000 -35.47%, #332500 60.33%, #000 111.66%, #000 212.59%, #332500 292.99%), #D9D9D9;
  overflow: hidden;
}

/* Add your existing styles for Header and Coins components below if needed */

body::-webkit-scrollbar {
  display: none;
}
/* For Firefox */
body {
  scrollbar-width: none;
}