/* services.css */

.AdnanceMainContainer {
  color: white;
}

.serviceHeading {
  color: white;
  font-size: 4.5rem;
  justify-content: center;
  text-align: center;
  font-family: sans-serif;
  margin-top: 10%;
}

.CustomBoxContainer {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 20.00%, #000 90.13%);
  /* padding: 20px; */
  /* margin: 5px; */
  padding-bottom: 16%;
  width: 100%;
}

.innercontainerbox {
  display: flex;
  flex-direction: column;
  /* Arrange items vertically */
  /* background-color: green; */
  width: 20%;
}

.CustomBoxContent {
  text-align: center;
  /* background-color: red; */
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.icon1style {
  width: 50px;
  /* Adjust the width as needed */
  height: 50px;
  /* Adjust the height as needed */
  justify-content: center;
  margin-left: 40%;
}

.exclusiveHeading {
  color: white;
  font-size: 3.0rem;
  font-family: sans-serif;
  margin-top: 5%;
  text-align: center;
}

/* services.css */

.exclusivePara {
  color: #B0B0B0;
  font-size: 1.5rem;
  font-family: sans-serif;
  margin-top: 5%;
  width: 75%;
  text-align: left;
  margin-top: 10%;
  margin-left: 11%;
  word-spacing: 2px;
  /* background-color: rebeccapurple; */
}

.exclusivePara::after {
  content: "";
  display: inline-block;
  /* width: 10%; */
}

@media (min-width: 800px) and (max-width: 1166px) {
  .innercontainerbox {
    display: flex;
    flex-direction: column;
    /* Arrange items vertically */
    /* background-color: green; */
    width: 30%;
  }

  .exclusiveHeading {
    color: white;
    font-size: 2.5rem;
    font-family: sans-serif;
    margin-top: 5%;
    text-align: center;
  }
}

@media (min-width: 354px) and (max-width: 768px) {
  .innercontainerbox {
    display: flex;
    flex-direction: column;
    /* Arrange items vertically */
    /* background-color: green; */
    width: 70%;
  }

  .exclusiveHeading {
    color: white;
    font-size: 2.4rem;
    font-family: sans-serif;
    margin-top: 15%;
    text-align: center;
    margin-left: 11%;
  }

  .serviceHeading {
    color: white;
    font-size: 3.5rem;
    justify-content: center;
    text-align: center;
    font-family: sans-serif;
    margin-top: 25%;
  }

  .exclusivePara {
    color: #B0B0B0;
    font-size: 1.2rem;
    font-family: sans-serif;
    margin-top: 5%;
    width: 90%;
    text-align: left;
    margin-top: 10%;
    margin-left: 15%;
    word-spacing: 2px;
    /* background-color: rebeccapurple; */
  }
  
  .exclusivePara::after {
    content: "";
    display: inline-block;
    /* width: 10%; */
  }
  .icon1style {
    width: 50px;
    /* Adjust the width as needed */
    height: 50px;
    /* Adjust the height as needed */
    justify-content: center;
    margin-left: 40%;
    margin-top: 30%;
  }
  .NavLink_App_Smaller_Devices{
    background-color: red;
  }
}