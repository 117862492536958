body {
    margin-bottom: 0%;
    padding: 0;
}



.last-screen {
    /* position: relative; */
    background-image: url('../../../Assets/bigCoins.svg');
    /* background: linear-gradient(180deg, #000 0%, #332500 29.17%, #000 44.79%, #000 75.52%, #332500 100%); */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0 20px 0  ;

}

.common1 {
    font-size: 1.7rem;
    font-weight: bold;
    text-align: left;
    padding-top: 7%;

}

.box {
    /* position: absolute; */
    padding: 40px;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(20, 20, 20, 0.71));
    backdrop-filter: blur(16.760705947875977px);
    border-radius: 20px;
    margin-left: 25%;
    opacity: 1.0;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: sans-serif;
    /* margin-top: 25%; */
}

.box1-h1 {
    text-align: center;
    color: white;
    font-size: 44px;
    padding-bottom: 80px;
}

.name-of-games1 {
    font-weight: bold;
    margin-top: 30%;
    font-size: 1.5rem;
    padding-top: 55px;
}

.name-of-games2 {
    font-size: 17px;
    padding-top: 55px;
    font-weight: bold;
}

.flex {
    display: flex;
    justify-content: space-between;
    width: 60%;
}


.boxes-flex {
    display: flex;
    justify-content: space-between;
    font-size: 1.7rem;
    font-weight: bold;
    /* gap: 10px; */
    line-height: 60%;
    width: 100%;
}

.boxes-flex1 {
    display: flex;
    font-size: 1.5rem;
    font-weight: bold;
    gap: 10px;
}

.innerbox11 {
    background-color: white;
    padding: 10px;
    color: black;
    margin-bottom: 25px;
    margin-top: 20px;
    text-align: center;
    border-radius: 6px;
    transition: 0.2s;
}

.innerbox1 {
    padding: 10px 10px;
    background-color: rgba(29, 29, 29, 1);
    color: white;
    margin-bottom: 25px;
    margin-top: 20px;
    border-radius: 6px;
    transition: 0.2s;
    text-align: center;
}

.common2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.innerbox2 {
    padding: 10px;
    background-color: rgba(29, 29, 29, 1);
    color: white;
    margin-bottom: 25px;
    margin-top: 20px;
    text-align: center;
    border-radius: 6px;
    transition: 0.2s;
}


.innerbox3 {
    padding: 10px;
    background-color: rgba(29, 29, 29, 1);
    color: white;
    margin-bottom: 25px;
    margin-top: 20px;
    text-align: center;
    border-radius: 6px;
    transition: 0.2s;
}

.innerbox1:hover {
    color: black;
    border-radius: 7.075px;
    border: 0px solid #1500B1;
    background: #FDFF02;
    box-shadow: 0px 0px 19px 0px #FDFF01;
}

.innerbox2:hover {
    color: black;
    border-radius: 7.075px;
    border: 0px solid #1500B1;
    background: #FDFF02;
    box-shadow: 0px 0px 19px 0px #FDFF01;
}

.innerbox3:hover {
    color: black;
    color: black;
    border-radius: 7.075px;
    border: 0px solid #1500B1;
    background: #FDFF02;
    box-shadow: 0px 0px 19px 0px #FDFF01;
}

.button-buy-now {
    color: #000;
    background-color: rgba(253, 255, 2, 1);
    font-size: 17px;
    font-weight: bold;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    justify-content: center;
    align-self: center;
    height: 7vh;
    margin-top: 10%;
    width: 30%;
}

.button-buy-now:hover {
    color: black;
    border-radius: 7.075px;
    border: 0px solid #1500B1;
    background: #FDFF02;
    box-shadow: 0px 0px 19px 0px #FDFF01;
}


@media (min-width: 355px) and (max-width: 1440px) {
    .button-buy-now {
        color: #000;
        background-color: rgba(253, 255, 2, 1);
        font-size: 17px;
        font-weight: bold;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-self: center;
        height: 12vh;
        margin-top: 10%;
        width: 35%;
    }

}

@media (max-width: 1335px) {
    .button-buy-now {
        color: #000;
        background-color: rgba(253, 255, 2, 1);
        font-size: 21px;
        font-weight: bold;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-self: center;
        height: 10vh;
        margin-top: 10%;
        width: 35%;
    }

    .box {
        /* position: absolute; */
        padding: 80px;
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(20, 20, 20, 0.71));
        backdrop-filter: blur(16.760705947875977px);
        border-radius: 20px;
        margin-left: 10%;
        opacity: 1.0;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: sans-serif;
        /* margin-top: 25%; */
    }

}

@media (min-width: 800px) and (max-width: 1166px) {
    .button-buy-now {
        color: #000;
        background-color: rgba(253, 255, 2, 1);
        font-size: 17px;
        font-weight: bold;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-self: center;
        height: 5vh;
        margin-top: 10%;
        width: 55%;
    }

    .box {
        /* position: absolute; */
        padding: 80px;
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(20, 20, 20, 0.71));
        backdrop-filter: blur(16.760705947875977px);
        border-radius: 20px;
        margin-left: 10%;
        opacity: 1.0;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: sans-serif;
        /* margin-top: 25%; */
    }

}
@media (min-width: 354px) and (max-width: 768px) {
    .button-buy-now {
        color: #000;
        background-color: rgba(253, 255, 2, 1);
        font-size: 17px;
        font-weight: bold;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        justify-content: center;
        align-self: center;
        height: 7vh;
        margin-top: 10%;
        width: 75%;
    }

    .box {
        /* position: absolute; */
        padding: 80px;
        background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(20, 20, 20, 0.71));
        backdrop-filter: blur(16.760705947875977px);
        border-radius: 20px;
        margin-left: 7%;
        opacity: 1.0;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        font-family: sans-serif;
        /* margin-top: 25%; */
    }
    .box1-h1 {
        text-align: center;
        color: white;
        font-size: 22px;
        padding-bottom: 15px;
    }
    .box{
        width: 85%;
    }
    .name-of-games1 {
        font-weight: bold;
        margin-top: 47%;
        font-size: 1.4rem;
        padding-top: 55px;
        width: 100%;
    }
    .name-of-games2 {
        font-weight: bold;
        /* margin-top: 47%; */
        font-size: 1.4rem;
        padding-top: 53.5px;
        width: 100%;
    }
    .boxes-flex1 {
        display: flex;
        font-size: 1.2rem;
        font-weight: bold;
        gap: 10px;
    }
    .boxes-flex{
        margin-left: 5%;
        /* margin-top: 4%; */
    }
    .innerbox1{
        font-size: 1.4rem;
        margin-left: 5%;
    }
    .innerbox2{
        font-size: 1.4rem;
    }
    .innerbox3{
        font-size: 1.4rem;
    }
}